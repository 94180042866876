// productList.findProducts.controller.js
(function() {
    'use strict';

    angular
        .module('app.controllers')
        .controller('ProductListFindProductsController', ProductListFindProductsController);

    // Inject dependencies.
    ProductListFindProductsController.$inject = [
        '$scope',
        'ProductFilterService',
        'TableConfigService',
        'InstrumentService',
        '$location',
        'ProfilerService',
        'MediaQueryService',
        'PAGING',
        '$timeout',
        '$q',
        'FILTER_DEFAULT_DATA',
        'HistoryService',
        '$rootScope'
    ];

    function ProductListFindProductsController(
        vm,
        ProductFilterService,
        TableConfigService,
        InstrumentService,
        $location,
        ProfilerService,
        MediaQueryService,
        PAGING,
        $timeout,
        $q,
        FILTER_DEFAULT_DATA,
        HistoryService,
        $rootScope
    ) {
        var container = '.find-products',
            $container = $(container),
            url = '/product-filter',
            query = $location.search().search,
            filterValues = {};

        vm.container = $container;

        // Initial tab 
        vm.activeTab = {
            name: 'secondaryMarket',
            index: 1
        };
        
        // To render the filter before data is loaded from API
        vm.filterData = FILTER_DEFAULT_DATA;
        
        // Update tabs navigation when enter controller 
        $timeout(vm.$parent.updateNavigation);

        vm.instrumentCountToShowPaging = 5; // How many instruments to show "button all"
        vm.media = MediaQueryService.getMedia();
        vm.filterType = 'filter'; // Choose filter handling "filter" or "yieldMatrix"

        /**
         *  Parse url params to filter object
         *  If doesn't exist clear filter search to prevent caching old filterdata
         */
        if (query) {
            ProductFilterService.reset();
            filterValues.filter = window.getParamsFromUrl(query);

            //For mobile view show 20 instrument per page
            if (vm.media !== 'regular') {
                filterValues.filter.rowsPerPage = 20;
            }

        } else {
            filterValues = {};
            ProductFilterService.reset();

            //For mobile view show 20 instrument per page
            if (vm.media !== 'regular') {
                filterValues.filter = {
                    rowsPerPage: 20
                };
            }
        }

        vm.$watch('activeTab.index', function(index) {
            
            $timeout(function() {

                //Hide label prices indicative
                if (index + '' === '0') {
                    $('.label-prices-indicative').hide();
                } else {
                    $('.label-prices-indicative').show();
                }
            });
        });

        /**
         * Assign table template to instrument groups
         * @param {string} groupId - Instrument group id.
         */
        vm.assignTableTemplate = function(groupId) {
            return TableConfigService.get(groupId);
        };

        /**
         * Change tab.
         * @param {int} index - Index of selected tab.
         */
        vm.changeTab = function(index) {
            vm.showGroupNavigation = false;
            vm.activeTab.index = index;
            vm.activeTab.name = Object.keys(vm.tabs)[index];

            //Reset group navigation
            $timeout(function() {
                vm.showGroupNavigation = true;
            });

            // Force recalculation of mobile instrument table cells heights
            if (vm.media === 'mobile' || vm.media === 'medium') {
                $rootScope.$broadcast('TriggerCalculateCells', '');
            }
        };


        /**
         * Get Instrumentgroup list data
         * @param {object} group - Instrumentgroup.
         */
        function getInstrumentGroupListData(group, merge) {
            var additionalParams = {},
                currentGroupElement = $('[data-id="' + group.id + '"]'),
                deferred = $q.defer();

            // Add loader
            currentGroupElement.addClass('icon-loading');

            // Add additional params for list query (sorting)
            if (group.sorting) {
                additionalParams = {
                    sort: group.sorting
                };
            }

            // Add selected tab (inSubscription or not) to the query params
            try {
                angular.extend(additionalParams, {inSubscription: vm.activeTab.name === 'inSubscription' ? 1 : 0});
            } catch (e) {
                console.log(e);
            }

            // Get instruments
            InstrumentService.getProducts(
                group,
                url,
                ProductFilterService.getListQueryUrl(additionalParams)
            ).then(function(data) {
                handleSuccess(data, merge);
            }, function(err) {
                    console.log(err);
                    deferred.reject();
                }
            ).finally(handleFinallyCallback);

            function handleSuccess(data, merge) {

                //Merge old with new table data
                if (merge) {
                    Array.prototype.push.apply(group.rows, data.rows);
                } else { //Overwrite
                    angular.extend(group, data);
                }
            }

            function handleFinallyCallback() {
                currentGroupElement.removeClass('icon-loading');
                deferred.resolve();
            }

            return deferred.promise;
        }


        /**
         * Increase quantity of rows to default paging
         * @param {object} group - Instrumentgroup.
         */
        vm.showAll = function(group) {
            var deferred = $q.defer();

            // Init paging
            if (!group.paging) {
                ProductFilterService.setPaging(vm.tabs);
            }

            // Set default rows per page
            group.paging.rowsPerPage = PAGING.rowsPerPage;

            // Enable paging, also removes "button all"
            group.pagingEnabled = true;

            // Update only one group
            getInstrumentGroupListData(group).finally(function() {

                // Restore page position after show all action finished
                $timeout(function() {
                    HistoryService.restoreGroupPages(group.id);
                });

                deferred.resolve();
            });

            // Store action for history
            HistoryService.storeExpandedGroup(group.id);

            return deferred.promise;
        };

        /**
         * Sort group by calling backend api
         * @param group
         */
        vm.sortGroup = function(group) {

            if (vm.activeTab) {

                // Check if paging already enabled or not
                if (!group.pagingEnabled && group.paging) {
                    group.paging.rowsPerPage = 5;
                }

                getInstrumentGroupListData(group);
            }


        };

        /**
         * Sort group by calling backend api (for Mobile)
         * @param group
         */
        vm.sortGroupMobile = function(group) {

            if (vm.activeTab && Number(vm.activeTab.index) === 1) {

                // Check if paging already enabled or not
                if (!group.pagingEnabled && group.paging) {
                    group.paging.rowsPerPage = 5;
                }

                // Force going to 1st page while sorting in mobile
                group.paging.page = 1;

                getInstrumentGroupListData(group);
            }


        };


        /**
         * Update paging of specific instrumentgroup
         * ProductFilterService.groups already updated by pagingDirective (group.paging)   
         */
        vm.updatePaging = function(data, merge) {
            var deferred = $q.defer();
            getInstrumentGroupListData(data, merge).finally(function() {
                deferred.resolve();
            });

            return deferred.promise;
        };


        /**
         * Set query data defaults for filter
         * @param {string} filterType - "filter" or "yieldMatrix"
         */
        ProductFilterService.setQueryData(vm.filterType);


        /**
         * Initially submit filter to get instrument data
         * @param {function} ProductFilterService returns query URL
         */
        $container.addClass('icon-loading');

        if (HistoryService.isCurrentlyAllowedToRestore && HistoryService.restoreData()) {
            handleSuccessData(HistoryService.restoreData(), 'ignore');
            handleFinallyCallback();
        } else {
            ProductFilterService.submit(
                url,
                ProductFilterService.getQueryURL(filterValues.filter, false, vm.filterData)
            ).then(handleSuccessData, function() {
                console.log('Error: Submit Filter');
            }).finally(handleFinallyCallback);
        }

        function handleSuccessData(data, ignore) {

            // Store data for back button cache
            if (ignore !== 'ignore') {
                HistoryService.storeData(data);
            }

            // Set filter data
            vm.filterData = data;

            // Set list data
            vm.tabs = {
                inSubscription: data.in_subscription,
                secondaryMarket: data.secondary_market
            };

            // Init paging
            ProductFilterService.setPaging(vm.tabs);

            // Initially render all tabs
            $timeout(function() {
                $container.find('tab').show();

                // Show only tab 1 after all tabs rendered
                $timeout(function() {
                    $container.find('tab[index="0"]').hide();
                });

                // Start restoring previous navigation state
                HistoryService.restore(vm.tabs, vm.activeTab, vm);

            });
        }

        function handleFinallyCallback() {
            $timeout(function() {
                ProfilerService.setTimeLoad();
                vm.showGroupNavigation = true;
                $container.removeClass('icon-loading');

                //Show label prices indicative
                $('.label-prices-indicative').show();
            }, 50);
        }

        // Get instrument documents
        vm.getDocuments = function(event, instrument) {
            event.stopPropagation();

            var $container = $(event.target);

            //Set flyout top position
            function setPosition() {
                $('flyout-documents').css('top', $container.position().top + $container.closest('section').position().top + 40);
                vm.showFlyOutDocuments = 'downloads';
                vm.flyOutDocumentsIdentifier = instrument.valor.value; // Set valor for product detail link in flyout
                vm.flyOutDocumentsValor = instrument.valor.value; // Set valor for product flyout
            }

            // Select flyout or modal regarding the current mediaquery
            function showFlyout() {

                // Set button active state
                $container.addClass('active');

                // Choose flyout or modalbox for mobile devices
                if (vm.media === 'mobile' || vm.media === 'medium') {
                    vm.showModal = true;
                    vm.modalDocumentsIdentifier = instrument.valor.value; // Set valor for product detail link
                } else {
                    setPosition();
                }
            }

            //Show flyout if document data is already added
            if (instrument.documents) {
                showFlyout();
                return;
            }

            // Get data using service
            $container.addClass('loading');
            InstrumentService.getDocuments(instrument.sin.value).then(function(data) {
                vm.flyoutInstrument = data;
            }).finally(function() {
                if (instrument.isKidAvailable && instrument.isKidAvailable.value) {
                    vm.flyoutInstrument.kid = InstrumentService.getKidDocuments(instrument.sin.value);
                }

                showFlyout();
                $container.removeClass('loading');
            });
        };

        /**
         * Apply local instrument table sort to all groups
         * It will be called from the instrumentTable directive
         * @param sortKey
         * @param direction
         */
        vm.applySortForAllGroups = function(sortKey, direction) {
            try {
                if (vm.tabs) {
                    angular.forEach(vm.tabs, function(tab) {
                        try {
                            if (tab.groups && tab.groups.length && tab.groups.length > 0) {
                                for (var i = 0; i < tab.groups.length; i++) {
                                    tab.groups[i].sorting = {};
                                    tab.groups[i].sorting[sortKey] = direction;
                                    tab.groups[i].sortingKey = sortKey;
                                    tab.groups[i].sortingIndex = direction;
                                }
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    });
                    vm.$apply();
                }

            } catch (e) {
                console.log(e);
            }
        };


        var configTab0 = {noSort: 'couponPercentPa'},
            configTab1 = {orderBy: 'underlyingNames',
                          sortBy: 'asc'};

        vm.getSortingConfig = function(tabKey) {

           if (tabKey === 'inSubscription') {
               return configTab0;
           } else {
               return configTab1;
           }

        };

    }
})();