// productDetail.controller.js
(function() {
    'use strict';

    angular
        .module('app.controllers')
        .controller('ProductDetailController', ProductDetailController);

    // Inject dependencies.
    ProductDetailController.$inject = [
        '$rootScope',
        '$scope',
        '$translate',
        '$state',
        '$timeout',
        'ProfilerService',
        '$stateParams',
        'InstrumentService',
        'TableConfigService',
        'ChartService',
        'MediaQueryService',
        'ApiService',
        'HelperService',
        'TooltipService',
        '$transition$'
    ];

    function ProductDetailController(
        $rootScope,
        vm,
        $translate,
        $state,
        $timeout,
        ProfilerService,
        $stateParams,
        InstrumentService,
        TableConfigService,
        ChartService,
        MediaQueryService,
        ApiService,
        HelperService,
        TooltipService,
        $transition$
    ) {
        var CHART_TIMESPANS = {
            ONE_MONTH: '1M',
            THREE_MONTHS: '3M',
            SIX_MONTHS: '6M',
            YEAR_TO_DATE: 'YTD',
            ONE_YEAR: '1Y',
            THIRTY_YEARS: '30Y'
        },
            container = '#product-detail',
            keyFiguresTab = '.keyfigures';
        vm.media = MediaQueryService.getMedia();
        vm.activeTab = 0;
        vm.isChartRenderFailed = false;

        // Reset profiler
        ProfilerService.resetProfiler();

        // Set browser title.
        $translate('browser_title_product_detail').then(function(data) {
            $rootScope.browserTitle = data;
        });

        vm.getTooltip = TooltipService.getTooltip;

        function isChartEngineLoaded() {
            return !!(
                vm.instrumentChart &&
                vm.instrumentChart.chart &&
                vm.instrumentChart.chart.chartEngine &&
                vm.instrumentChart.chart.chartEngine.chart);
        }

        //Change tab function
        vm.changeTab = function(index) {
            vm.activeTab = index;
            if (index === '1' && vm.data.displayKeyFigures) {
                if (isChartEngineLoaded()) {
                    vm.instrumentChart.chart.chartEngine.chart.validateNow(); // redraws the graph
                }
            }
        };

        // Open report file in new window
        vm.getReport = function(sin) {

            // decision to post chart data to backend or not
            if (vm.data.displayKeyFigures) {
                getProductReportWithChart(sin);
            } else {
                getProductReport(sin);
            }
        };

        /**
         * Method to get the product report with Chart.
         * @param sin
         */
        function getProductReportWithChart(sin) {
            try {

                // If the active tab is 0 (first tab) then we display the second tab (key figures) because it has the graph and tab needs to show contents for the graph be converted properly
                toggleTab(keyFiguresTab, 0, 'show');

                // set width equal to the graph displayed on desktop
                $('#chart-' + sin).css('width', '1180px');

                if (isChartEngineLoaded()) {
                    vm.instrumentChart.chart.chartEngine.chart.validateNow(); // redraws the graph
                }

                $timeout(function() {

                    // Once the pdf is generated, we restore the graph on mobile
                    ApiService.getProductReportWithChart(sin, container, vm.chartBenchmarks, vm.data.fields.valor.value).then(function() {
                        $('#chart-' + sin).css('width', '100%');
                        vm.instrumentChart.chart.chartEngine.chart.validateNow(); // redraws the graph
                    }, function(error) {
                        console.log('API call to getReport() failed', error);
                    }).finally(function() {

                        // We check if the active tab is with index 0, we hide the other tab
                        toggleTab(keyFiguresTab, 0, 'hide');
                    });
                }, 200);

            } catch (e) {
                console.log(e);
            }
        }

        /**
         * Method to get the product report
         * @param sin
         */
        function getProductReport(sin) {
            try {
                ApiService.getProductReport(sin, container);
            } catch (e) {
                console.log(e);
            }
        }

        /**
         * Toggle Tab by provided status for the provided tab if the active tab matches the provided tabIndex
         * @param tab
         * @param tabIndex
         * @param status
         */
        function toggleTab(tab, tabIndex, status) {
            if (parseInt(vm.activeTab) === tabIndex) {
                if (status === 'show') {
                    $(tab).show();
                }

                if (status === 'hide') {
                    $(tab).hide();
                }
            }
        }

        // Get documents using modal
        vm.getDocuments = function() {

            //Show modal
            if (vm.documents) {
                vm.showModal = 'productDetail';
                return;
            }

            // Get data using service
            $(container).addClass('icon-loading');
            InstrumentService.getDocuments(
                vm.data.fields.sin.value
            ).then(function(data) {
                vm.documents = data;
            }).finally(function() {
                ProfilerService.setTimeLoad();
                vm.showModal = 'productDetail';
                $(container).removeClass('icon-loading');
                getKidDocuments();
            });
        };

        // Get documents using flyout
        vm.getDocumentsFlyout = function(type) {

            // Instantly show flyout if data is already available
            if (vm.documents) {
                vm.showFlyOutDocuments = type;
                return;
            }

            /**
             * Get documents data
             * @param {object} sin - Identifier sin
             */
            $(container).addClass('icon-loading');
            InstrumentService.getDocuments(
                vm.data.fields.sin.value
            ).then(function(data) {
                vm.documents = data;
                vm.showFlyOutDocuments = type;
            }).finally(function() {
                ProfilerService.setTimeLoad();
                $(container).removeClass('icon-loading');
                getKidDocuments();
            });
        };

        // Go back
        vm.goBack = function() {
            if (history.length === 1) {
                $timeout(function() {
                    $state.go('home');
                });
            } else {
                history.back();
            }
        };

        // Check if Ask/Bid should be visible or not
        vm.priceAvailability = function(price) {
            if (typeof price === 'undefined' || price === null || price <= 0) {
                return false;
            }

            return true;
        };

        // If the currency is any character a-z or A-Z for example: CHF, USD, etc. we add a space before it.
        // Otherwise just display what's received.
        vm.displayCurrency = function(currency) {
            var displayCurrency = currency;
            if (/[a-zA-Z]+/.test(displayCurrency)) {
                displayCurrency = ' ' + displayCurrency;
            }

            return displayCurrency;
        };

        function getKidDocuments() {
            if (vm.data.fields.isKidAvailable.value) {
                vm.documents.kid = InstrumentService.getKidDocuments(vm.data.fields.sin.value);
            }
        }

        function onChartDataFetchError() {
            vm.isChartRenderFailed = true;
            toggleTab(keyFiguresTab, 0, 'hide');
        }

        //Init charts
        function initChart() {
            var i,
                colors = ['#E2B224', '#96C80F', '#4FA645', '#25529C', '#941E5B'];

            vm.chartTimespans = [
                CHART_TIMESPANS.ONE_MONTH,
                CHART_TIMESPANS.THREE_MONTHS,
                CHART_TIMESPANS.SIX_MONTHS,
                CHART_TIMESPANS.YEAR_TO_DATE,
                CHART_TIMESPANS.ONE_YEAR,
                CHART_TIMESPANS.THIRTY_YEARS
            ];
            vm.chartBenchmarks = [];

            // Get benchmarks
            if (vm.data.underlyings.length > 0) {
                for (i = 0; i < vm.data.underlyings.length; i++) {
                    if (vm.data.underlyings[i].sin && vm.data.underlyings[i].underlying) {
                        vm.chartBenchmarks.push({
                            label: vm.data.underlyings[i].underlying.value,
                            sin: vm.data.underlyings[i].sin.value,
                            color: colors[i] || '#666666',
                            active: true
                        });
                    }
                }
            }

            // Init chart
            try {
                vm.instrumentChart = new ChartService();
                vm.instrumentChart.init({
                    container: $('#chart-' + vm.data.chart.data.sin),
                    identifier: 'sin',
                    id: vm.data.chart.data.sin,
                    timespan: CHART_TIMESPANS.THIRTY_YEARS,
                    baseUrl: '/product-chart',
                    benchmarks: vm.chartBenchmarks,
                    theme: (vm.media === 'mobile') ? 'mobile' : null,
                    valor: 'Valor: ' + vm.data.fields.valor.value,
                    onChartDataFetchError: onChartDataFetchError
                }).finally(function() {
                    $timeout(function() {
                        toggleTab(keyFiguresTab, 0, 'hide');
                    }, 50);
                });
            } catch (e) {
                console.log(e);
            }
        }

        /**
         * Highlight keywords.
         */
        function highlightKeywords() {

            TooltipService.applyTooltips();

            // Add listener to close tooltip.
            var clickHandler = function(e) {
                if (!$(e.target).hasClass('highlight-tooltip')) {
                    e.stopPropagation();
                    $('.tooltip').removeClass('show');
                    $('.highlight-tooltip').removeClass('active');
                }
            };

            // Add event.
            $(window).click(clickHandler);

            // Fix for iOS
            var detectTap,
                touchstartHandler = function() {
                    detectTap = true; //detects all touch events
                },

                touchmoveHandler = function() {
                    detectTap = false; //Excludes the scroll events from touch events
                },

                touchendHandler = function(event) {
                    if (event.type === "click") {
                        detectTap = true; //detects click events
                    }

                    if (detectTap) {
                        clickHandler(event);
                    }
                };



            // Fix for iOS
            if (vm.media === 'mobile') {
                $(document).on('touchstart', touchstartHandler);
                $(document).on('touchmove', touchmoveHandler);
                $(document).on('click touchend', touchendHandler);
            }

            // Remove listener.
            vm.$on('$destroy', function() {

                //Hide tooltip on destroy
                $('.tooltip').removeClass('show');
                $('.highlight-tooltip').removeClass('active');

                $(window).off('click', clickHandler);

                // Fix for iOS
                if (vm.media === 'mobile') {
                    $(document).off('touchstart', touchstartHandler);
                    $(document).off('touchmove', touchmoveHandler);
                    $(document).off('click touchend', touchendHandler);
                }
            });
        }

        // Get instrument data
        $(container).addClass('icon-loading');

        var stateParams = $transition$.params();
        InstrumentService.get(
            stateParams.identifier,
            stateParams.identifierValue
        ).then(function(data) {

            //Set data
            vm.data = data;
            vm.underlyingData = {
                list: {
                    rows: data.underlyings ? data.underlyings : []
                }
            };
            vm.dividends = {
                list: {
                    rows: data.dividends ? data.dividends : []
                }
            };


            //Assign table template using groupId
            vm.tableTpl = TableConfigService.get(data.fields.groupId.value).productDetail;

            // Use underlyings table to display basket components if the basketComponents are set in group config
            if (vm.tableTpl.basketComponents) {
                vm.underlyingData = {
                    list: {
                        rows: data.basketComponents ? data.basketComponents : []
                    }
                };
            }

            $timeout(function() {

                // Highlight keywords.
                highlightKeywords();
                if (typeof $(keyFiguresTab)[0] !== 'undefined') {
                    initChart();
                }
            }, 1000);
        }).finally(function() {
            ProfilerService.setTimeLoad();
            $(container).removeClass('icon-loading');

        });

        /**
         * Fetch Insrument Group's template and return field precision
         * @param {Number} defaultPrecision
         * @param {string} fieldLabel
         * @param {array} tpl
         * @returns {Number}
         */
        vm.getPrecisionFromTpl = function(defaultPrecision, fieldLabel, tpl) {
            try {
                for (var i = 0; i < tpl.length; i++) {
                    if (tpl[i].label === fieldLabel && tpl[i].precision) {
                        return tpl[i].precision;
                    }
                }
            } catch (e) {
            }

            // Return default value
            return defaultPrecision;
        };

        /**
         * Apply decimal digits Min Max as requested in #62363 internal note #20
         * @param field
         * @param rawValue
         * @returns {*}
         */
        vm.getSpecialFormat = function(field, rawValue) {
            return HelperService.getSpecialFormat(field, rawValue);
        };

        /**
         * Get min/max precision based on provided value. Using Min max values defined in SpecialFormatMinMaxFields
         * @param rawValue
         * @returns {*}
         */
        vm.getMinMaxPrecision = function(rawValue) {
            return HelperService.formatWithPrecisionMinMax(rawValue);
        };

        /**
         * Get Fixed Precision
         * @param field
         * @param value
         * @param precision
         * @returns {*}
         */
        vm.getFixedPrecision = function(field, value, precision) {
            return HelperService.getFixedPrecision(field, value, precision);
        };

        /**
         * Get Legal Document Name (Termsheet or Final Terms)
         * based on backend legalDocumentsName property
         * @param data
         */
        vm.getLegalDocumentsName = function(data) {
            if (data && data.legalDocumentsName) {
                return data.legalDocumentsName;
            }

            // If no legalDocumentsName, use Termsheet as default
            return 'termsheet';
        };

        vm.shouldShowFootNote = function() {
            return !((vm.tableTpl && vm.tableTpl.hideFootnoteText) || (vm.data && vm.data.inSubscription));
        };

    }
})();
