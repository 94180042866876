// DualCurrencyNote.constants.js
(function() {
    'use strict';

    angular
        .module('app.constants')
        .constant("DualCurrencyNote", {
            "secondaryMarket": [
                {
                    label: 'maxPerformancePercentPaPriceCurrency',
                    value: 'maxPerformancePercentPaStrikeCurrency',
                    precision: 2
                },
                {
                    label: 'capLevel',
                    value: 'capLevel',
                    precision: 2
                },
                {
                    label: 'underlyingNames',
                    value: 'underlyingNames',
                    icon: 'settlementMethodIcon',
                    minWidth: 200 // Minimum column width in pixels in the instrument table
                },
                {
                    label: 'investmentCurrency',
                    value: 'realPriceCurrency'
                },
                {
                    label: 'alternativeCurrency',
                    value: 'counterCurrencyCalculated'
                },
                {
                    label: 'maturityDate',
                    value: 'maturityDate',
                    icon: 'earlyRedemptionIcon',
                    format: 'mediumDate'
                },
                {
                    label: 'bid',
                    value: 'bid'
                },
                {
                    label: 'ask',
                    value: 'ask'
                },
                {
                    label: ' ',
                    value: 'menu',
                    align: 'right',
                    notSortable: true
                }
            ],
            "productDetail": {
                "strikeLabel": 'strikeLevelAndMaxReturn',
                "strike": [
                    {
                        label: 'capLevel',
                        value: 'underlyingNameWithCap'
                    },
                    {
                        label: 'maxReturn',
                        value: 'discountPercentStrikeCurrency'
                    }
                ],
                "lifecycle": [
                    {
                        label: 'product_detail_subscriptionPeriodEndDate',
                        value: 'subscriptionPeriodEndDate',
                        visibleIf: 'inSubscription'
                    },
                    {
                        label: 'product_detail_fixingDate',
                        value: 'fixingDate'
                    },
                    {
                        label: 'product_detail_issueDate',
                        value: 'issueDate'
                    },
                    {
                        label: 'lastTradingDate',
                        value: 'lastTradingDate'
                    },
                    {
                        label: 'product_detail_maturityDate',
                        value: 'maturityDate'
                    },
                    {
                        label: 'product_detail_redemptionDate',
                        value: 'redemptionDate'
                    },
                    {
                        label: 'days2MaturityDate',
                        value: 'days2MaturityDate',
                        precision: 0
                    }
                ],
                "categorization": [
                    {
                        label: 'product_category',
                        value: 'metaMetaGroupName'
                    },
                    {
                        label: 'metaGroupName',
                        value: 'metaGroupName'
                    },
                    {
                        label: 'groupName',
                        value: 'groupName'
                    }
                ],
                "staticData": [
                    {
                        label: 'issuer',
                        value: 'issuer'
                    },
                    {
                        label: 'underlyingNames',
                        value: 'underlyingNames'
                    },
                    {
                        label: 'issuePrice',
                        value: 'issuePrice',
                        precision: 2
                    },
                    {
                        label: 'investmentCurrency',
                        value: 'realPriceCurrency'
                    },
                    {
                        label: 'alternativeCurrency',
                        value: 'counterCurrencyCalculated'
                    },
                    {
                        label: 'settlementMethod',
                        value: 'settlementMethod'
                    },
                    {
                        label: 'notionalValue',
                        value: 'notionalValue'
                    },
                    {
                        label: 'listing',
                        value: 'inListing'
                    }
                ],
                "pattern": [
                    {
                        label: 'performanceSinceIssuePercent',
                        value: 'performanceSinceIssuePercent'
                    },
                    {
                        label: 'maxPerformancePercentStrikeCurrency',
                        value: 'maxPerformancePercentStrikeCurrency'
                    },
                    {
                        label: 'maxPerformancePercentPaPriceCurrency',
                        value: 'maxPerformancePercentPaStrikeCurrency'
                    }
                ],
                "underlyings": [
                    {
                        label: 'underlying',
                        value: 'underlyingNames'
                    },
                    {
                        label: 'price',
                        value: 'price',
                        precision: 2
                    },
                    {
                        label: 'performance_since_issue_percent',
                        value: 'performanceSinceIssuePercent'
                    },
                    {
                        label: 'reference_price_absolute',
                        value: 'referencePriceAbsolute'
                    },
                    {
                        label: 'capLevel',
                        value: 'cap',
                        precision: 2
                    }
                ],
                "hideFootnoteText": true
            }
        });
})();