var lang_de_CH = {
    "1D": "1D",
    "1M": "1M",
    "1Y": "1Y",
    "3M": "3M",
    "6M": "6M",
    "30Y": "MAX",
    "abort": "Abbrechen",
    "accept_legal_information": "Ich akzeptiere die rechtlichen Hinweise für Strukturierte Produkte.",
    "accept": "Akzeptieren",
    "access_restriction": "Zugriffsbeschränkung",
    "accruedInterestPriCurPercent": "Marchzinsen",
    "active_filter": "Aktive Filter",
    "adjust": "Anpassen",
    "filter_count_mobile": "Filter anpassen",
    "advanced_filters": "Erweiterte Filter",
    "all": "Alle",
    "annually": "Jährlich",
    "apply": "Übernehmen",
    "ask_criteria": "Brief",
    "ask": "Brief",
    "mainmenu": "Hauptmenü",
    "at_expiry": "Bei Verfall",
    "Autocallable_SoftCallable_MultiBarrierReverseConvertible": "Autocallable/Softcallable <br/>Multi Barrier Reverse Convertible",
    "AutocallableBarrierReverseConvertible": "Autocallable Barrier Reverse Convertible",
    "AutocallableBarrierReverseConvertibles": "Autocallable Barrier Reverse Convertible",
    "AutocallableBarrierReverseConvertibleWithConditionalCoupon": "Autocallable Barrier Reverse Convertible mit bedingtem Coupon",
    "AutocallableBarrierReverseConvertiblesWithConditionalCoupon": "Autocallable Barrier Reverse Convertible mit bedingtem Coupon",
    "AutocallableBarrierReverseConvertibleWithConditionalMemoryCoupon": "Autocallable Barrier Reverse Convertible mit bedingtem Memory Coupon",
    "AutocallableBarrierReverseConvertiblesWithConditionalMemoryCoupon": "Autocallable Barrier Reverse Convertible mit bedingtem Memory Coupon",
    "AutocallableMultiBarrierReverseConvertible": "Autocallable Multi Barrier Reverse Convertible",
    "AutocallableMultiBarrierReverseConvertibles": "Autocallable Multi Barrier Reverse Convertible",
    "AutocallableMultiBarrierReverseConvertibleWithConditionalCoupon": "Autocallable Multi Barrier Reverse Convertible mit bedingtem Coupon",
    "AutocallableMultiBarrierReverseConvertiblesWithConditionalCoupon": "Autocallable Multi Barrier Reverse Convertible mit bedingtem Coupon",
    "AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCoupon": "Autocallable Multi Barrier Reverse Convertible mit bedingtem Memory Coupon",
    "AutocallableMultiBarrierReverseConvertiblesWithConditionalMemoryCoupon": "Autocallable Multi Barrier Reverse Convertible mit bedingtem Memory Coupon",
    "AutocallableMultiReverseConvertible": "Autocallable Multi Reverse Convertible",
    "AutocallableMultiReverseConvertibles": "Autocallable Multi Reverse Convertible",
    "AutocallableMultiReverseConvertibleWithConditionalCoupon": "Autocallable Multi Reverse Convertible mit bedingtem Coupon",
    "AutocallableMultiReverseConvertiblesWithConditionalCoupon": "Autocallable Multi Reverse Convertible mit bedingtem Coupon",
    "AutocallableMultiReverseConvertibleWithConditionalMemoryCoupon": "Autocallable Multi Reverse Convertible mit bedingtem Memory Coupon",
    "AutocallableMultiReverseConvertiblesWithConditionalMemoryCoupon": "Autocallable Multi Reverse Convertible mit bedingtem Memory Coupon",
    "AutocallableReverseConvertible": "Autocallable Reverse Convertible",
    "AutocallableReverseConvertibles": "Autocallable Reverse Convertible",
    "AutocallableReverseConvertibleWithConditionalCoupon": "Autocallable Reverse Convertible mit bedingtem Coupon",
    "AutocallableReverseConvertibleWithConditionalCoupons": "AutocallableReverseConvertibleWithConditionalCoupons",
    "AutocallableReverseConvertiblesWithConditionalCoupon": "Autocallable Reverse Convertible mit bedingtem Coupon",
    "AutocallableReverseConvertibleWithConditionalMemoryCoupon": "Autocallable Reverse Convertible mit bedingtem Memory Coupon",
    "AutocallableReverseConvertiblesWithConditionalMemoryCoupon": "Autocallable Reverse Convertible mit bedingtem Memory Coupon",
    "back": "Zurück",
    "barrier_too_close_multi_notification": "Der Risikopuffer ist fast ausgeschöpft (Abstand zur Barriere des schwächsten Basiswert <5.00%).",
    "show_alternative_products": "Alternativprodukte anzeigen",
    "barrier_too_close_single_notification": "Der Risikopuffer ist fast ausgeschöpft (Abstand zur Barriere <5.00%).",
    "barrier": "Barriere",
    "barrierHitProbability2MaturityPercent": "Barrier Hit Probability",
    "barrierLevelAbsoluteReferenceCurrency": "Barriere",
    "barrierLevelRelativePercent": "Barrierelevel",
    "barrierObservationMethod": "Barrieretyp",
    "BarrierReverseConvertible": "Barrier Reverse Convertible",
    "BarrierReverseConvertibles": "Barrier Reverse Convertible",
    "barrierTouched_criteria": "Barriereverletzung",
    "barrierTouched": "Barriereverletzung",
    "betaVersion": "Beta-Version",
    "betaVersionText": "Beta-Version mit reduziertem Umfang",
    "bi-monthly": "Alle 2 Monate",
    "bi-weekly": "Zweiwöchentlich",
    "bid": "Geld",
    "BonusCertificate": "Bonus-Zertifikat",
    "TrackerCertificates": "Tracker-Zertifikat",
    "TrackerCertificate": "Tracker-Zertifikat (statisch)",
    "TrackerCertificateName": "LUKB Tracker-Zertifikat",
    "TrackerCertificateCategory": "SSPA Kategorie: 1300 - Partizipation",
    "TrackerCertificateOpenEnd": "Tracker-Zertifikat Open End (statisch)",
    "TrackerCertificateOpenEndName": "LUKB Tracker-Zertifikat",
    "TrackerCertificateOpenEndCategory": "SSPA Kategorie: 1300 - Partizipation",
    "TemporaryProduct": "Temporary Product",
    "browser_title_product_detail": "Produktdetailseite",
    "call": "Anrufen",
    "cancel": "Abbrechen",
    "CapitalProtectionCertificatesWithParticipation": "Kapitalschutz-Zertifikat mit Partizipation",
    "CapitalProtectionCertificateWithParticipation": "Kapitalschutz-Zertifikat mit Partizipation",
    "CapitalProtectionCertificateWithParticipationWorstOf": "Kapitalschutz-Zertifikat mit Partizipation am worst of",
    "CapitalProtectionCertificatesWithParticipationWorstOf": "Kapitalschutz-Zertifikat mit Partizipation am worst of",
    "CapitalProtectionProduct": "Kapitalschutz-Zertifikat",
    "CapitalProtectionProducts": "Kapitalschutz-Zertifikat",
    "CapitalProtections": "Kapitalschutz",
    "CapitalProtection": "Kapitalschutz",
    "CapitalProtectionWithParticipation": "Kapitalschutz-Zertifikat mit Partizipation",
    "CapitalProtectionWithParticipationWorstOf": "Kapitalschutz-Zertifikat mit Partizipation am worst of",
    "CapitalProtectionWithParticipationCapped": "Kapitalschutz-Zertifikat mit Capped Partizipation",
    "CapitalProtectionWithParticipationSumBasket": "Kapitalschutz-Zertifikat mit Partizipation am Basket",
    "CapitalProtectionWithParticipationSumBasket_SPPNameGeneration": "Kapitalschutz-Zertifikat mit Partizipation",
    "CapitalProtectionWithParticipationCappedSumBasket": "Kapitalschutz-Zertifikat mit Capped Partizipation am Basket",
    "CapitalProtectionWithParticipationCappedSumBasket_SPPNameGeneration": "Kapitalschutz-Zertifikat mit Capped Partizipation",
    "CapitalProtectionWithConditionalCouponWorstOf": "Kapitalschutz-Zertifikat mit Bedingtem Coupon",
    "CapitalProtectionWithConditionalCouponWorstOf_SPPNameGeneration": "Kapitalschutz-Zertifikat mit %s Bedingtem Coupon",
    "CapitalProtectionWithConditionalMemoryCouponWorstOf_SPPNameGeneration": "Kapitalschutz-Zertifikat mit %s Bedingtem Memory Coupon",
    "cash_settlement": "Barabwicklung",
    "cash": "Bar",
    "cashorphysical": "Bar oder physische Lieferung",
    "categorization": "Kategorisierung",
    "change_criteria": "Kriterien ändern",
    "session_close": "Tagesschlusskurs",
    "chart_disclaimer_BarrierReverseConvertible": "Produktperformance, max. Rendite bis Verfall, max. Rendite p.a. und Barrier Hit Probability basieren auf aktuellen Kursen.<br/>Performance Basiswert und Basiswertpreis basieren auf dem Vortagesschlusskurs.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_DiscountCertificate": "Produktperformance seit Emission, max. Rendite bis Verfall und max. Rendite p.a. basieren auf aktuellen Kursen.<br/>Performance Basiswert und Basiswertpreis basieren auf dem Vortagesschlusskurs.",
    "chart_disclaimer_AutocallableBarrierReverseConvertibleWithConditionalMemoryCoupon": "Produktperformance, max. Rendite bis Verfall, max. Rendite p.a. und Barrier Hit Probability basieren auf aktuellen Kursen.<br/>Performance Basiswert und Basiswertpreis basieren auf dem Vortagesschlusskurs.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_AutocallableBarrierReverseConvertibleWithConditionalCoupon": "Produktperformance, max. Rendite bis Verfall, max. Rendite p.a. und Barrier Hit Probability basieren auf aktuellen Kursen.<br/>Performance Basiswert und Basiswertpreis basieren auf dem Vortagesschlusskurs.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_MultiBarrierReverseConvertible": "Produktperformance, max. Rendite bis Verfall, max. Rendite p.a. und Barrier Hit Probability basieren auf aktuellen Kursen.<br/>Performance schwächster Basiswert, schwächster Basiswert und Basiswertpreise basieren auf den Vortagesschlusskursen.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCoupon": "Produktperformance, max. Rendite bis Verfall, max. Rendite p.a. und Barrier Hit Probability basieren auf aktuellen Kursen.<br/>Performance schwächster Basiswert, schwächster Basiswert und Basiswertpreise basieren auf den Vortagesschlusskursen.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_AutocallableMultiBarrierReverseConvertibleWithConditionalCoupon": "Produktperformance, max. Rendite bis Verfall, max. Rendite p.a. und Barrier Hit Probability basieren auf aktuellen Kursen.<br/>Performance schwächster Basiswert, schwächster Basiswert und Basiswertpreise basieren auf den Vortagesschlusskursen.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_AutocallableBarrierReverseConvertible": "Produktperformance, max. Rendite bis Verfall, max. Rendite p.a., Yield to Call und Barrier Hit Probability basieren auf aktuellen Kursen.<br/>Performance Basiswert und Basiswertpreis basieren auf dem Vortagesschlusskurs.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_SoftCallableBarrierReverseConvertible": "Produktperformance, max. Rendite bis Verfall, max. Rendite p.a., Yield to Call und Barrier Hit Probability basieren auf aktuellen Kursen.<br/>Performance Basiswert und Basiswertpreis basieren auf dem Vortagesschlusskurs.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_AutocallableMultiBarrierReverseConvertible": "Produktperformance, max. Rendite bis Verfall, max. Rendite p.a., Yield to Call und Barrier Hit Probability basieren auf aktuellen Kursen.<br/>Performance schwächster Basiswert, schwächster Basiswert und Basiswertpreise basieren auf den Vortagesschlusskursen.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_SoftCallableMultiBarrierReverseConvertible": "Produktperformance, max. Rendite bis Verfall, max. Rendite p.a., Yield to Call und Barrier Hit Probability basieren auf aktuellen Kursen.<br/>Performance schwächster Basiswert, schwächster Basiswert und Basiswertpreise basieren auf den Vortagesschlusskursen.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_ReverseConvertible": "Produktperformance, max. Rendite bis Verfall und max. Rendite p.a. basieren auf aktuellen Kursen.<br/>Performance Basiswert und Basiswertpreis basieren auf dem Vortagesschlusskurs.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_AutocallableReverseConvertibleWithConditionalMemoryCoupon": "Produktperformance, max. Rendite bis Verfall und max. Rendite p.a. basieren auf aktuellen Kursen.<br/>Performance Basiswert und Basiswertpreis basieren auf dem Vortagesschlusskurs.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_AutocallableReverseConvertiblesWithConditionalCoupon": "Produktperformance, max. Rendite bis Verfall und max. Rendite p.a. basieren auf aktuellen Kursen.<br/>Performance Basiswert und Basiswertpreis basieren auf dem Vortagesschlusskurs.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_MultiReverseConvertible": "Produktperformance, max. Rendite bis Verfall und max. Rendite p.a. basieren auf aktuellen Kursen.<br/>Performance schwächster Basiswert, schwächster Basiswert und Basiswertpreise basieren auf den Vortagesschlusskursen.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_AutocallableMultiReverseConvertibleWithConditionalMemoryCoupon": "Produktperformance, max. Rendite bis Verfall und max. Rendite p.a. basieren auf aktuellen Kursen.<br/>Performance schwächster Basiswert, schwächster Basiswert und Basiswertpreise basieren auf den Vortagesschlusskursen.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_AutocallableMultiReverseConvertibleWithConditionalCoupon": "Produktperformance, max. Rendite bis Verfall und max. Rendite p.a. basieren auf aktuellen Kursen.<br/>Performance schwächster Basiswert, schwächster Basiswert und Basiswertpreise basieren auf den Vortagesschlusskursen.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_AutocallableReverseConvertible": "Produktperformance, max. Rendite bis Verfall, max. Rendite p.a. und Yield to Call basieren auf aktuellen Kursen.<br/>Performance Basiswert und Basiswertpreis basieren auf dem Vortagesschlusskurs.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_SoftCallableReverseConvertible": "Produktperformance, max. Rendite bis Verfall, max. Rendite p.a. und Yield to Call basieren auf aktuellen Kursen.<br/>Performance Basiswert und Basiswertpreis basieren auf dem Vortagesschlusskurs.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_AutocallableMultiReverseConvertible": "Produktperformance, max. Rendite bis Verfall, max. Rendite p.a. und Yield to Call basieren auf aktuellen Kursen.<br/>Performance schwächster Basiswert, schwächster Basiswert und Basiswertpreise basieren auf den Vortagesschlusskursen.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_SoftCallableMultiReverseConvertible": "Produktperformance, max. Rendite bis Verfall, max. Rendite p.a. und Yield to Call basieren auf aktuellen Kursen.<br/>Performance schwächster Basiswert, schwächster Basiswert und Basiswertpreise basieren auf den Vortagesschlusskursen.<br/>Die Kennzahl \"Produktperformance seit Emission\" berücksichtigt sowohl die Kursentwicklung des Produkts als auch die seit Emission ausbezahlten Coupons.",
    "chart_disclaimer_CapitalProtectionWithParticipation": "Produktperformance basiert auf aktuellen Kursen.<br/>Performance Basiswert, Abstand Strike und Basiswertpreis basieren auf dem Vortagesschlusskurs.",
    "chart_disclaimer_CapitalProtectionWithParticipationCapped": "Produktperformance basiert auf aktuellen Kursen.<br/>Performance Basiswert, Abstand Strike und Basiswertpreis basieren auf dem Vortagesschlusskurs.",
    "chart_disclaimer_CapitalProtectionWithParticipationCappedSumBasket": "Produktperformance basiert auf aktuellen Kursen.<br/>Performance Basiswert, Abstand Strike und Basiswertpreis basieren auf dem Vortagesschlusskurs.",
    "chart_disclaimer_CapitalProtectionWithParticipationWorstOf": "Produktperformance basiert auf aktuellen Kursen.<br/>Performance schwächster Basiswert, schwächster Basiswert, Abstand Strike und Basiswertpreise basieren auf den Vortagesschlusskursen.",
    "chart_disclaimer_CapitalProtectionWithParticipationCappedWorstOf": "Produktperformance basiert auf aktuellen Kursen.<br/>Performance schwächster Basiswert, schwächster Basiswert, Abstand Strike und Basiswertpreise basieren auf den Vortagesschlusskursen.",
    "chart_disclaimer_TrackerCertificate": "Produktperformance basiert auf aktuellen Kursen. Basiswertpreise basieren auf den Vortagesschlusskursen.",
    "chart_disclaimer_ActivelyManagedTrackerCertificate": "Produktperformance basiert auf aktuellen Kursen.",
    "chart_disclaimer_CapitalProtectionWithParticipationSumBasket": "Produktperformance basiert auf aktuellen Kursen.<br/>Performance Basiswert, Abstand Strike und Basiswertpreis basieren auf dem Vortagesschlusskurs.",
    "chart_disclaimer_CapitalProtectionWithConditionalCouponWorstOf": "Produktperformance basiert auf aktuellen Kursen.<br/>Performance schwächster Basiswert, schwächster Basiswert, Abstand Strike und Basiswertpreise basieren auf den Vortagesschlusskursen.",
    "chart_disclaimer_CapitalProtectionWithConditionalMemoryCouponWorstOf": "Produktperformance basiert auf aktuellen Kursen.<br/>Performance schwächster Basiswert, schwächster Basiswert, Abstand Strike und Basiswertpreise basieren auf den Vortagesschlusskursen.",
    "conditionalCouponPercentPa": "Coupon p.a.",
    "confirm_residences": "Ich bestätige, dass ich meinen Wohnsitz/Sitz in der Schweiz habe.",
    "contact_person": "Ansprechpartner",
    "contact": "Kontakt",
    "continuous": "kontinuierlich",
    "couponInterest": "Coupon",
    "couponPaymentDate": "Zahlungstag",
    "couponPaymentDateAndInterest": "Couponzahlungstage",
    "couponPaymentFrequency": "Couponzahlung",
    "couponPercentPa": "Coupon p.a.",
    "couponPercentPa_criteria": "Coupon p.a. %",
    "coupons": "Coupon",
    "create_pdf_from_site": "PDF von Seite erstellen",
    "criteria": "Kriterien",
    "currency": "Währung",
    "daily_close_prices": "Tagesschlusskurs",
    "daily": "Täglich",
    "days2MaturityDate": "Restlaufzeit bis Endfixierungsdatum in Tagen",
    "disclaimer_footer": "Haben Sie Ihren Wohnsitz/Sitz in einem anderen Land, sind Sie nicht berechtigt, auf die Informationen von LUKB emittierten Strukturierten Produkten zuzugreifen. In diesem Fall bitten wir Sie, den Vorgang abzubrechen.",
    "disclaimer_text": "Um auf die Produktinformationen zugreifen zu können, müssen Sie folgende Bedingungen erfüllen:",
    "DiscountCertificate": "Discount-Zertifikat",
    "display_all_underlyings": "Alle Basiswerte anzeigen",
    "display_all": "Alle anzeigen",
    "display_all_products": "Alle Produkte anzeigen",
    "distance2BarrierLevelPercent": "Abstand  Barriere",
    "distance2BarrierLevelPercent_criteria": "Abstand  Barriere %",
    "distance2ClosestBarrierLevelPercent": "Abstand Barriere",
    "distance2StrikePercent": "Abstand Strike",
    "distance2StrikePercent_criteria": "Abstand Strike %",
    "distance2triggerLevelRefCurrencyPercent": "Abstand Autocall",
    "distance2triggerLevelRefCurrencyPercent_criteria": "Abstand Autocall %",
    "distanceRange2BarrierLevel": "Abstand Barriere",
    "download": "Download",
    "downloads": "Downloads",
    "dr": "Dr.",
    "early_redemption": "Vorzeitige Rückzahlung",
    "earlyRedemptionList": "Vorzeitige Rückzahlung",
    "email_address": "E-Mailadresse",
    "error_email": "Bitte geben Sie eine gültige E-Mailadresse ein.",
    "error_full_name": "Bitte geben Sie einen Vor- und Nachname ein.",
    "error_message": "Bitte geben Sie Ihre Nachricht ein.",
    "error_phone": "Bitte geben Sie eine Telefonnummer ein.",
    "error_salutation": "Bitte wählen Sie eine Anrede aus.",
    "every": "jede",
    "factsheet_dl": "Factsheet herunterladen",
    "factsheet": "Factsheet",
    "false": "Nein",
    "filter": "Filter",
    "filter_barrierObservationMethod": "Barrieretyp",
    "filter_currency": "Währung",
    "filter_distanceRange2BarrierLevel": "Risikopuffer",
    "filter_group": "Produktgruppe/Produkttyp",
    "filter_maturityDate": "Verfall",
    "filter_products": "Produkte filtern",
    "filter_underlying": "Basiswert",
    "first_last_name": "Vor- und Nachname",
    "found_products": "Gefundene Produkte",
    "glossary_disclaimer": "*Bei Produkten mit mehreren Basiswerten handelt es sich um den schwächsten Basiswertkurs",
    "glossary": "Glossar",
    "group_names": "Produkttypen",
    "groupName": "Produkttyp",
    "hasTouchedBarrier": "Barriereverletzung",
    "header_structured_products": "Strukturierte Produkte",
    "hide_all_underlyings": "Alle Basiswerte ausblenden",
    "id_ex_swx_exists_criteria": "EX SWX ID existiert",
    "id_ex_swx_exists": "EX SWX ID existiert",
    "in_subscription_notification": "Dieses Produkt ist bis {{subscriptionPeriodEndDate}}, {{subscriptionPeriodEndDateTime}} Uhr in Zeichnung.",
    "inListing_criteria": "Produkt kotiert",
    "inListing": "Produkt kotiert",
    "isin": "ISIN",
    "isQuanto": "Quanto",
    "issued_products": "Emittierte Produkte",
    "issuePrice": "Emissionspreis",
    "issuer": "Emittentin",
    "issuerCallNoticePeriodEndDate": "Beobachtungsdatum",
    "key_figures": "Kennzahlen",
    "lang_DE": "Deutsch",
    "lang_EN": "Englisch",
    "lang_FR": "Französisch",
    "lang_IT": "Italienisch",
    "lastTradingDate": "Letzter Handelstag",
    "less_then_one_month": "< 1 Monat",
    "lifecycle": "Lebenszyklus",
    "light_footer_title": "Wir sind für Sie da",
    "listing": "Kotierung",
    "market_data_source": "Marktdaten werden durch Morningstar und <a href='http://www.solvians.com' title='Solvians' target='_blank'>Solvians</a> zur Verfügung gestellt.",
    "maturityDate": "Verfall",
    "max_performance_too_low_notification": "Dieses Produkt nähert sich der maximalen Rendite (<1.50%).",
    "max": "Max.",
    "MAX": "Max.",
    "maxPerformancePercentPaPriceCurrency": "Max. Rendite p.a.",
    "maxPerformancePercentPaPriceCurrency_criteria": "Max. Rendite p.a. %",
    "maxPerformancePercentPaStrikeCurrency": "Bedingter Memory Coupon p.a.",
    "maxPerformancePercentStrikeCurrency_criteria": "Max. Rendite bis Verfall %",
    "maxPerformancePercentStrikeCurrency": "Max. Rendite bis Verfall",
    "maxPerformancePercentPriceCurrency": "Max. Rendite bis Verfall",
    "distance2CapPercentStrikeCurrency_criteria": "Abstand Cap %",
    "distance2CapPercentStrikeCurrency": "Abstand Cap",
    "distance2CapAbsoluteStrikeCurrency_level": "Abstand Cap Level",
    "relativeCapStrCur2ReferencePrice_criteria": "Cap Level in %",
    "relativeCapStrCur2ReferencePrice": "Cap Level",
    "reference_price_absolute": "Initial Fixing",
    "discount": "Discount",
    "strikeLevelAndDiscount": "Cap Level und Discount",
    "metaGroup": "Produktgruppe",
    "metaGroupName": "Produktgruppe",
    "min": "Min.",
    "month": "Monat",
    "monthly": "monatlich",
    "months_dat": "Monaten",
    "months": "Monate",
    "more_then_2_years": "> 2 Jahre",
    "mr": "Herr",
    "ms": "Frau",
    "MultiBarrierReverseConvertible": "Multi Barrier Reverse Convertible",
    "MultiBarrierReverseConvertibles": "Multi Barrier Reverse Convertible",
    "MultiReverseConvertible": "Multi Reverse Convertible",
    "MultiReverseConvertibles": "Multi Reverse Convertible",
    "Max_Yeild_At_Expiry": "Max. Rendite bis Verfall",
    "name_term": "Name, Begriff...",
    "no_document_available": "Kein Dokument verfügbar",
    "no_results": "Keine Ergebnisse",
    "no_search_results": "Keine Suchergebnisse",
    "no": "Nein",
    "notification_link": "Hier finden Sie eine Selektion von Produkten.",
    "notionalValue": "Nominal",
    "observationDate": "Beobachtungsdatum",
    "observationDateTrim": "Beobachtungs-<br />datum",
    "on": "auf",
    "one_to_three_months": "1 - 3 Monate",
    "one_to_two_years": "1 - 2 Jahre",
    "onlyQuotedProducts": "Nur kotierte Produkte anzeigen",
    "open_end": "Open End",
    "participationRatePercent": "Partizipation",
    "Participations": "Partizipation",
    "pdf_create": "Produktreport",
    "performance_since_issue_percent": "Performance seit Emission",
    "performance": "Wertentwicklung",
    "performance2NextObservationDatePriceCurrency_criteria": "Yield to Call %",
    "performance2NextObservationDatePriceCurrency": "Yield to Call",
    "performanceSinceIssuePercent": "Produktperformance seit Emission",
    "distance2StrikePercentForWorstPerformanceUnderlying": "Abstand zum Strike schwächster Basiswert",
    "phone_by_day": "Telefon (tagsüber)",
    "phone": "Telefon",
    "physical_settlement": "Physische ETF Lieferung",
    "physical": "Physische Lieferung",
    "price": "Kurswert",
    "product_barrier_observed_on_expiry": "Barrierebeobachtung bei Verfall",
    "product_cash_settlement": "Barabwicklung",
    "product_category": "Produktkategorie",
    "capitalProtectionAndStrike": "Kapitalschutzlevel und Strike Level",
    "participationAndCap": "Partizipation und Cap Level",
    "barrierAndStrike": "Barriere und Strike Level",
    "product_detail_fixingDate": "Anfangsfixierungsdatum",
    "product_detail_issueDate": "Liberierungsdatum",
    "product_detail_maturityDate": "Endfixierungsdatum",
    "product_detail_redemptionDate": "Rückzahlungsdatum",
    "product_detail_subscriptionPeriodEndDate": "Zeichnungsschlussdatum",
    "product_early_redemption_option": "Vorzeitige Rückzahlungsmöglichkeit",
    "product_group_product_type": "Produktgruppe/Produkttyp",
    "product_overview_performance_key_indicators": "Produktübersicht, Performance und Kennzahlen",
    "product_overview": "Produktübersicht",
    "product_physical_delivery": "Physische ETF Lieferung",
    "product_quanto": "Das Produkt ist währungsgesichert",
    "product_type_criteria": "Produktgruppe / Produkttyp",
    "product_type_search_placeholder": "Produktgruppe / Produkttyp...",
    "product_type": "Produkttyp",
    "product_underlying_search_placeholder": "Name, Begriff...",
    "product": "Produkt",
    "products_documents": "Produktdetails anzeigen",
    "products_in_subscription_of_lukb": "Produkte in Zeichnung der Luzerner Kantonalbank",
    "products_in_subscription": "Produkte in Zeichnung",
    "products_range_0": "3 Monate",
    "products_range_1": "6 Monate",
    "products_range_2": "9 Monate",
    "products_range_3": "1 Jahr",
    "products_range_4": "18 Monate",
    "products_range_5": "> 18 Monate",
    "products": "Produkte",
    "protectionLevelRelativePercent": "Kapitalschutzlevel",
    "quanto": "Quanto",
    "quarterly": "vierteljährlich",
    "quotingMethod": "Preisstellung",
    "clean": "Clean",
    "dirty": "Dirty",
    "realPriceCurrency": "Währung",
    "redemptionDate": "Verfall",
    "relativeStrike2ReferencePrice": "Strike Level",
    "repayment": "Vorzeitige Rückzahlungsmöglichkeit",
    "repaymentLevelPriCurPercent": "Nächstes Autocall Beobachtungsdatum",
    "repaymentLevelPriCurPercentSoftCallable": "Nächstes Beobachtungsdatum",
    "repaymentValue": "Rückzahlung",
    "reset_all_filter": "Alle Filter zurücksetzen",
    "ReverseConvertible": "Reverse Convertible",
    "ReverseConvertibles": "Reverse Convertible",
    "salutation": "Anrede",
    "search_groups_on": " auf",
    "search_placeholder": "Valor / ISIN / Symbol / Basiswert / Produkttyp",
    "search": "Produktsuche",
    "select_language": "Sprache wählen",
    "semi-annually": "halbjährlich",
    "send_copy_to_email": "Kopie an meine E-Mail-Adresse senden",
    "send_message": "Nachricht senden",
    "settlementMethod": "Abwicklungsart",
    "show_documents (not used anymore)": "Dokumente anzeigen",
    "show_product_detail_page": "Produktdetails anzeigen",
    "show_product": "Produkt anzeigen",
    "show_products": "Produkte anzeigen",
    "shown_for_7_days": "Diesen Disclaimer für 7 Tage nicht mehr anzeigen.",
    "six_symbol": "Symbol",
    "six_to_twelve_months": "6 - 12 Monate",
    "SoftCallableBarrierReverseConvertible": "Softcallable Barrier Reverse Convertible",
    "SoftCallableBarrierReverseConvertibles": "Softcallable Barrier Reverse Convertible",
    "SoftCallableMultiBarrierReverseConvertible": "Softcallable Multi Barrier Reverse Convertible",
    "SoftCallableMultiBarrierReverseConvertibles": "Softcallable Multi Barrier Reverse Convertible",
    "SoftCallableMultiReverseConvertible": "Softcallable Multi Reverse Convertible",
    "SoftCallableMultiReverseConvertibles": "Softcallable Multi Reverse Convertible",
    "SoftCallableReverseConvertible": "Softcallable Reverse Convertible",
    "SoftCallableReverseConvertibles": "Softcallable Reverse Convertible",
    "starting_after": "erstmals nach",
    "static_data": "Stammdaten",
    "strike": "Strike",
    "structured_products_lukb": "Strukturierte Produkte der Luzerner Kantonalbank",
    "subscriptionPeriodEndDate": "Zeichnungsschluss",
    "subscriptionPeriodEndDateMobile": "Zeichnungs-<br/>schluss-<br/>",
    "tab_find_products": "Produkte<br/>finden",
    "tab_opportunities_in_the_secondary_market": "Opportunitäten<br/>im Sekundärmarkt",
    "tab_opportunities_secondary_market_header": "Auf dieser Seite finden Sie eine Selektion von attraktiven Barrier Reverse Convertible, welche auch vorzeitige Rückzahlungseigenschaften aufweisen können. Die Auswahl hat aufgrund von vordefinierten Kriterien - wie Preis, Barriere und Yield to Call - stattgefunden. Alle Produkte können direkt an der Börse gehandelt werden.",
    "tab_products_in_subscription": "Produkte<br/>in Zeichnung",
    "tab_recently_issued": "Kürzlich<br/>emittiert",
    "tab_yield_matrix_title": "Die Renditematrix ist ein mehrwertbringendes Arbeitsinstrument. Dieses Tool ermöglicht Ihnen, die attraktivsten Barrier Reverse Convertibles, Multi Barrier Reverse Convertibles sowie Autocallable/Softcallable Barrier Reverse Convertibles im Sekundärmarkt zu finden. Kombinieren Sie Basiswerte, Risikopuffer und Laufzeit, um die optimale Anlagelösung für Ihre Anlagebedürfnisse zu finden. Bei Klick auf die untenstehenden Renditen nach Restlaufzeit gelangen Sie direkt zum passenden Produkt.",
    "tab_yield_matrix": "Rendite-<br/>matrix",
    "termsheet_dl": "Termsheet herunterladen",
    "final_terms_dl": "Endgültige Bedingungen herunterladen",
    "termsheet": "Termsheet",
    "final_terms": "Endgültige Bedingungen",
    "third_level_filter_legend": "Die vorgewählten Werte beziehen sich auf die minimalen und maximalen Werte über alle Produkte des ausgewählten Filters.",
    "three_to_six_months": "3 - 6 Monate",
    "top_of_page": "Seitenanfang",
    "triggerLevel": "Autocall Trigger Level",
    "triggerLevelRefCurAbsolute": "Autocall Trigger",
    "true": "Ja",
    "underlying": "Basiswert",
    "underlyingNames": "Basiswerte",
    "valor": "Valor",
    "weekly": "wöchentlich",
    "worstPerformanceUnderlying": "Schwächster Basiswert",
    "underlyingPerformance2ReferencePriceRelative": "Kursentwicklung Basiswert seit Emission",
    "worstUnderlyingPerformance2ReferencePriceRelative": "Kursentwicklung schwächster Basiswert seit Emission",
    "write_message_desktop": "Nachricht<br />schreiben",
    "write_message_mobile": "Nachricht schreiben",
    "write_message_tooltip": "Nachricht schreiben",
    "write_us": "Schreiben Sie uns",
    "year": "Jahr",
    "years_dat": "Jahren",
    "years": "Jahre",
    "yes": "Ja",
    "YieldEnhancements": "Renditeoptimierung",
    "YieldEnhancement": "Renditeoptimierung",
    "your_message": "Ihre Nachricht",
    "YTD": "YTD",
    "barrier_observation_on_maturity": "Beobachtung bei Verfall",
    "contact_success_message": "<b>Vielen Dank für Ihre Mitteilung</b><br />Wir werden Ihre Anfrage so schnell wie möglich bearbeiten.<br/>Ihre Luzerner Kantonalbank",
    "contact_error_message": "Ein Fehler ist aufgetreten. Bitte versuche es später erneut.",
    "lang_prospectus": "Prospekt",
    "prospectus": "Prospekt",
    "chart_error": "Keine Daten verfügbar",
    "corporate_action_executed": "Eine Corporate Action hat stattgefunden. Weitere Details finden Sie unter <a href='/services/mitteilungen'>Mitteilungen</a>.",
    "maturity_notification": "Das Produkt ist verfallen.",
    "check_products_in_subscription": "Produkte in Zeichnung prüfen",
    "early_redeemed_notification": "Dieses Produkt wurde zur vorzeitigen Rückzahlung gekündigt.",
    "customDateTime": "dd.MM.y, HH:mm 'Uhr'",
    "prices_indicative": "Geld- und Briefkurse sind indikativ.",
    "footnote_text": "Aufgrund der angewendeten Berechnungslogik können in diversen Kennzahlen zwischen Coupon Ex-Datum und Couponzahlungstag Sprünge auftauchen.",
    "product_filter_info_no_match": "Die ausgewählten Kriterien führen zu keinen Suchergebnissen.",
    "subscription_no_products": "Aktuell sind keine Produkte in Zeichnung. Schauen Sie sich unsere Produkte im Sekundärmarkt an.",
    "issued_no_products": "Aktuell sind keine kürzlich emittierte Produkte. Schauen Sie sich unsere Produkte im Sekundärmarkt an.",
    "barrier_touched_notification": "Die Barriere wurde durch {{touchedBarrierUnderlyingName}} am {{firstTouchedBarrierDate}} verletzt.",
    "product_details": "Product Details",
    "phone_disclaimer": "*Gespräche über diese Linie werden aufgezeichnet.",
    "phone_disclaimer_contact": "*Gespräche über diese Linien werden aufgezeichnet.",
    "cookiesBannerDisclaimer": "Klicken Sie auf den OK-Button, sollten Sie mit der Verwendung von Statistik- und Marketing-Cookies auf unserer Website einverstanden sein. Weitere Informationen dazu finden Sie <a href='/cookies'>hier</a>.",
    "contact_form": "Kontaktformular: strukturierteprodukte.lukb.ch",
    "capLevel": "Cap Level",
    "product_report": "Produktreport",
    "hours": "Uhr",
    "tel": "Tel",
    "Hit": "Hit",
    "customDateTimePhpFormat": "d.m.Y, H:i \\U\\h\\r",
    "BarrierReverseConvertibleName": "LUKB Barrier Reverse Convertible",
    "BarrierReverseConvertibleCategory": "SSPA Kategorie: 1230 - Renditeoptimierung",
    "BarrierReverseConvertibleDescription": "LUKB Barrier Reverse Convertibles bieten Anlegern die Möglichkeit, von Bewegungen des Basiswerts zu profitieren, die seitwärts oder über seinem Barrierelevel verlaufen. Diese Produkte schütten einen garantierten Coupon aus und bieten einen Risikopuffer bis zum Barrierelevel. Für diese Produkte ist keine vorzeitige Rückzahlung vorgesehen.",
    "MultiBarrierReverseConvertibleName": "LUKB Multi Barrier Reverse Convertible",
    "MultiBarrierReverseConvertibleCategory": "SSPA Kategorie: 1230 - Renditeoptimierung",
    "MultiBarrierReverseConvertibleDescription": "LUKB Multi Barrier Reverse Convertibles bieten Anlegern die Möglichkeit, von Bewegungen der Basiswerte zu profitieren, die seitwärts oder über ihren jeweiligen Barrierelevels verlaufen. Diese Produkte schütten einen garantierten Coupon aus und bieten einen Risikopuffer bis zum Barrierelevel, angewendet auf den Basiswert mit der schwächsten Wertentwicklung. Für diese Produkte ist keine vorzeitige Rückzahlung vorgesehen.",
    "AutocallableBarrierReverseConvertibleName": "LUKB Autocallable Barrier Reverse Convertible",
    "AutocallableBarrierReverseConvertibleCategory": "SSPA Kategorie: 1230 - Renditeoptimierung",
    "AutocallableBarrierReverseConvertibleDescription": "LUKB Autocallable Barrier Reverse Convertibles bieten Anlegern die Möglichkeit, von Bewegungen des Basiswerts zu profitieren, die seitwärts oder über seinem Barrierelevel verlaufen. Diese Produkte schütten einen garantierten Coupon aus und bieten einen Risikopuffer bis zum Barrierelevel. Diese Produkte können automatisch zur vorzeitigen Rückzahlung zu 100.00% des Nominals gekündigt werden, abhängig vom Level des Basiswerts an einem der Autocallbeobachtungsdaten.",
    "AutocallableMultiBarrierReverseConvertibleName": "LUKB Autocallable Multi Barrier Reverse Convertible",
    "AutocallableMultiBarrierReverseConvertibleCategory": "SSPA Kategorie: 1230 - Renditeoptimierung",
    "AutocallableMultiBarrierReverseConvertibleDescription": "LUKB Autocallable Multi Barrier Reverse Convertibles bieten Anlegern die Möglichkeit, von Bewegungen der Basiswerte zu profitieren, die seitwärts oder über ihren jeweiligen Barrierelevels verlaufen. Diese Produkte schütten einen garantierten Coupon aus und bieten einen Risikopuffer bis zum Barrierelevel, angewendet auf den Basiswert mit der schwächsten Wertentwicklung. Diese Produkte können automatisch zur vorzeitigen Rückzahlung zu 100.00% des Nominals gekündigt werden, abhängig vom Level des Basiswerts mit der schwächsten Wertentwicklung an einem der Autocallbeobachtungsdaten.",
    "SoftCallableBarrierReverseConvertibleName": "LUKB Softcallable Barrier Reverse Convertible",
    "SoftCallableBarrierReverseConvertibleCategory": "SSPA Kategorie: 1230 - Renditeoptimierung",
    "SoftCallableBarrierReverseConvertibleDescription": "LUKB Softcallable Barrier Reverse Convertibles bieten Anlegern die Möglichkeit, von Bewegungen des Basiswerts zu profitieren, die seitwärts oder über seinem Barrierelevel verlaufen. Diese Produkte schütten einen garantierten Coupon aus und bieten einen Risikopuffer bis zum Barrierelevel. Die Emittentin hat das Recht, alle Produkte zur vorzeitigen Rückzahlung zu 100.00% des Nominals zu kündigen.",
    "SoftCallableMultiBarrierReverseConvertibleName": "LUKB Softcallable Multi Barrier Reverse Convertible",
    "SoftCallableMultiBarrierReverseConvertibleCategory": "SSPA Kategorie: 1230 - Renditeoptimierung",
    "SoftCallableMultiBarrierReverseConvertibleDescription": "LUKB Softcallable Multi Barrier Reverse Convertibles bieten Anlegern die Möglichkeit, von Bewegungen der Basiswerte zu profitieren, die seitwärts oder über ihren jeweiligen Barrierelevels verlaufen. Diese Produkte schütten einen garantierten Coupon aus und bieten einen Risikopuffer bis zum Barrierelevel, angewendet auf den Basiswert mit der schwächsten Wertentwicklung. Die Emittentin hat das Recht, alle Produkte zur vorzeitigen Rückzahlung zu 100.00% des Nominals zu kündigen.",
    "ReverseConvertibleName": "LUKB Reverse Convertible",
    "ReverseConvertibleCategory": "SSPA Kategorie: 1220 - Renditeoptimierung",
    "ReverseConvertibleDescription": "LUKB Reverse Convertibles bieten Anlegern die Möglichkeit, von Bewegungen des Basiswerts zu profitieren, die seitwärts oder über seinem Ausübungspreis verlaufen. Diese Produkte schütten einen garantierten Coupon aus und bieten einen Risikopuffer bis zum Ausübungspreis. Für diese Produkte ist keine vorzeitige Rückzahlung vorgesehen.",
    "MultiReverseConvertibleName": "LUKB Multi Reverse Convertible",
    "MultiReverseConvertibleCategory": "SSPA Kategorie: 1220 - Renditeoptimierung",
    "MultiReverseConvertibleDescription": "LUKB Multi Reverse Convertibles bieten Anlegern die Möglichkeit, von Bewegungen der Basiswerte zu profitieren, die seitwärts oder über ihren jeweiligen Ausübungspreisen verlaufen. Diese Produkte schütten einen garantierten Coupon aus und bieten einen Risikopuffer bis zum Ausübungspreis, angewendet auf den Basiswert mit der schwächsten Wertentwicklung. Für diese Produkte ist keine vorzeitige Rückzahlung vorgesehen.",
    "AutocallableMultiReverseConvertibleName": "LUKB Autocallable Multi Reverse Convertible",
    "AutocallableMultiReverseConvertibleCategory": "SSPA Kategorie: 1220 - Renditeoptimierung",
    "AutocallableMultiReverseConvertibleDescription": "LUKB Autocallable Multi Reverse Convertibles bieten Anlegern die Möglichkeit, von Bewegungen der Basiswerte zu profitieren, die seitwärts oder über ihren jeweiligen Ausübungspreisen verlaufen. Diese Produkte schütten einen garantierten Coupon aus und bieten einen Risikopuffer bis zum Ausübungspreis, angewendet auf den Basiswert mit der schwächsten Wertentwicklung. Diese Produkte können automatisch zur vorzeitigen Rückzahlung zu 100.00% des Nominals gekündigt werden, abhängig vom Level des Basiswerts mit der schwächsten Wertentwicklung an einem der Autocallbeobachtungsdaten.",
    "SoftCallableReverseConvertibleName": "LUKB Softcallable Reverse Convertible",
    "SoftCallableReverseConvertibleCategory": "SSPA Kategorie: 1220 - Renditeoptimierung",
    "SoftCallableReverseConvertibleDescription": "LUKB Softcallable Reverse Convertibles bieten Anlegern die Möglichkeit, von Bewegungen des Basiswerts zu profitieren, die seitwärts oder über seinem Ausübungspreis verlaufen. Diese Produkte schütten einen garantierten Coupon aus und bieten einen Risikopuffer bis zum Ausübungspreis. Die Emittentin hat das Recht, alle Produkte zur vorzeitigen Rückzahlung zu 100.00% des Nominals zu kündigen.",
    "SoftCallableMultiReverseConvertibleName": "LUKB Softcallable Multi Reverse Convertible",
    "SoftCallableMultiReverseConvertibleCategory": "SSPA Kategorie: 1220 - Renditeoptimierung",
    "SoftCallableMultiReverseConvertibleDescription": "LUKB Softcallable Multi Reverse Convertibles bieten Anlegern die Möglichkeit, von Bewegungen der Basiswerte zu profitieren, die seitwärts oder über ihren jeweiligen Ausübungspreisen verlaufen. Diese Produkte schütten einen garantierten Coupon aus und bieten einen Risikopuffer bis zum Ausübungspreis, angewendet auf den Basiswert mit der schwächsten Wertentwicklung. Die Emittentin hat das Recht, alle Produkte zur vorzeitigen Rückzahlung zu 100.00% des Nominals zu kündigen.",
    "CapitalProtectionWithParticipationName": "LUKB Kapitalschutz-Zertifikate mit Partizipation",
    "CapitalProtectionWithParticipationCategory": "SSPA Kategorie: 1100 - Kapitalschutz",
    "CapitalProtectionWithParticipationDescription": "LUKB Kapitalschutz-Zertifikate mit Partizipation bieten Anlegern die Möglichkeit, von einem steigenden Basiswert zu profitieren, kombiniert mit partiellem Kapitalschutz bei Verfall. Diese Produkte zahlen einen Mindestrückzahlungsbetrag in der Höhe des Kapitalschutzlevels zuzüglich unbeschränkter Partizipation an der Wertentwicklung des Basiswerts über dem Ausübungspreis, beides am Rückzahlungsdatum.",
    "CapitalProtectionWithParticipationWorstOfName": "LUKB Kapitalschutz-Zertifikate mit Partizipation am Worst of",
    "CapitalProtectionWithParticipationWorstOfCategory": "SSPA Kategorie: 1100 - Kapitalschutz",
    "CapitalProtectionWithParticipationWorstOfDescription": "LUKB Kapitalschutz-Zertifikate mit Partizipation am Worst of bieten Anlegern die Möglichkeit, von steigenden Basiswerten zu profitieren, kombiniert mit partiellem Kapitalschutz bei Verfall. Diese Produkte zahlen einen Mindestrückzahlungsbetrag in der Höhe des Kapitalschutzlevels zuzüglich unbeschränkter Partizipation an der Wertentwicklung des Basiswerts mit der schwächsten Wertentwicklung über seinem Ausübungspreis, beides am Rückzahlungsdatum.",
    "CapitalProtectionWithParticipationCappedWorstOf": "Kapitalschutz-Zertifikat mit Capped Partizipation am worst of",
    "CapitalProtectionWithConditionalMemoryCouponWorstOf": "Kapitalschutz-Zertifikat mit Bedingtem Memory Coupon",
    "range_0": "< 10.00%",
    "range_1": "10.00% - 20.00%",
    "range_2": "20.00% - 30.00%",
    "range_3": "30.00% - 40.00%",
    "range_4": "40.00% - 50.00%",
    "range_5": "> 50.00%",
    "php_date_iso8601_format": "Y-m-d\\TH:i:sP",
    "pdf_report_disclaimer": "Die hier enthaltenen Angaben haben ausschliesslich informativen Charakter und stellen keine Offerte dar. Trotz sorgfältiger Bearbeitung übernimmt die Luzerner Kantonalbank keine Garantie und Haftung für die Richtigkeit.",
    "once_after": "ein Mal nach",
    "observationDaysList": "Beobachtungsdaten Bedingter Coupon",
    "conditionalCouponPa": "Bedingter Coupon p.a.",
    "triggerObservationEndDate": "Beobachtungsdatum für Bedingten Coupon",
    "triggerLevelRefCurPercent": "Coupon Trigger Level",
    "paymentDate": "Zahlungstage Bedingter Coupon",
    "paymentAmount": "Bedingte Couponbeträge",
    "paidAmount": "",
    "paid": "Bezahlt",
    "paid_not": "Nicht bezahlt",
    "maturity": "Bei Verfall",
    "key_information_document": "BIB",
    "kid_dl": "BIB herunterladen",
    "dividendHandling": "Dividendenbehandlung",
    "Participation": "Partizipation",
    "distributing": "Barausgleich",
    "reinvesting": "Reinvestiert",
    "discounting": "Abdiskontiert",
    "ActivelyManagedTrackerCertificate": "Aktiv Verwaltetes Tracker-Zertifikat",
    "ActivelyManagedTrackerCertificateName": "LUKB Aktiv Verwaltetes Tracker-Zertifikat",
    "ActivelyManagedTrackerCertificateCategory": "SSPA Kategorie: 1300 - Partizipation",
    "basketComponent": "Basiswert",
    "weight": "Anfangsgewichtung",
    "basketCompPerf2RefPriceRelative": "Performance seit Emission",
    "coupon_has_not_triggered": "Keine Coupon Auslösung",
    "coupon_has_triggered": "Coupon Auslösung",
    "lifespan_text_callable": "Max. Laufzeit",
    "lifespan_text_non_callable": "Laufzeit",
    "newsletter_title": "Newsletter abonnieren",
    "newsletter_subtitle": "Melden Sie sich für unseren wöchentlichen Newsletter sowie unsere Anlageidee des Monats an und bleiben Sie stets auf dem Laufenden.",
    "newsletter_please_choose_type": "Bitte wählen Sie Ihre gewünschten Newsletter aus:",
    "newsletter_example": "Beispiel",
    "newsletter_products_in_subscription": "Produkte in Zeichnung (wöchentlich)",
    "newsletter_products_in_subscription_link": "https://nbild.lukb.ch/gallery/K7FBhDT0IgPnAbNTI6/Demo_NL_KSP_DE.html",
    "newsletter_secondary_market_opportunities": "Kaufgelegenheiten im Sekundärmarkt (wöchentlich)",
    "newsletter_secondary_market_opportunities_link": "https://nbild.lukb.ch/gallery/K7FBhDT0IgPnAbNTI6/Demo_NL_KSP_SEK_DE.html",
    "newsletter_form_field_gotoUrl": "https://strukturierteprodukte.lukb.ch/services/newsletter-authentifizierung",
    "newsletter_form_field_gotoConfirmationUrl": "https://strukturierteprodukte.lukb.ch/services/newsletter-bestaetigung",
    "newsletter_investment_ideas": "Anlageidee des Monats",
    "newsletter_languages": "Sprache des Newsletters",
    "newsletter_languages_DE": "Deutsch",
    "newsletter_languages_EN": "Englisch",
    "newsletter_languages_IT": "Italienisch",
    "newsletter_languages_FR": "Französisch",
    "newsletter_please_choose_contact": "Bitte geben Sie Ihre Kontaktdaten ein:",
    "newsletter_contact_title": "Anrede*",
    "newsletter_contact_mr": "Herr",
    "newsletter_contact_mrs": "Frau",
    "newsletter_contact_firstname": "Vorname*",
    "newsletter_contact_lastname": "Nachname*",
    "newsletter_contact_company": "Firma",
    "newsletter_contact_company_name": "Firma",
    "newsletter_contact_email": "E-Mail-Adresse*",
    "newsletter_privacy": "Ich bestätige, dass ich mit den <a target=\"_blank\" href=\"/rechtliche-hinweise#nutzungsbedingungen-copyright\">Nutzungsbedingungen</a> sowie der <a target=\"_blank\" href=\"/rechtliche-hinweise#datenschutz-datensicherheit\">Datenschutzerklärung</a> einverstanden bin.",
    "newsletter_required": "Alle mit * markierten Felder sind Pflichtfelder. Die Übermittlung Ihrer Daten erfolgt verschlüsselt.",
    "newsletter_submit": "Abonnieren",
    "newsletter_error_type": "Bitte wählen Sie mindestens einen Newsletter aus.",
    "newsletter_error_salutation": "Bitte wählen Sie eine Anrede aus.",
    "newsletter_error_firstname": "Bitte geben Sie einen Vornamen ein.",
    "newsletter_error_lastname": "Bitte geben Sie einen Nachnamen ein.",
    "newsletter_error_email": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
    "newsletter_error_privacy": "Bitte bestätigen Sie die Datenschutzbestimmungen.",
    "newsletter_form_field_gotoErrorUrl": "https://strukturierteprodukte.lukb.ch/services/mail-error",
    "quoting_method_value": "in Stücken",
    "basket": "Basket",
    "am": "am",
    "AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCouponName": "LUKB Autocallable Multi Barrier Reverse Convertible mit bedingtem Memory Coupon",
    "AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCouponCategory": "SSPA Kategorie: 1260 - Renditeoptimierung",
    "CapitalProtectionWithConditionalMemoryCouponWorstOfName": "LUKB Kapitalschutz-Zertifikat mit Bedingtem Memory Coupon",
    "CapitalProtectionWithConditionalMemoryCouponWorstOfCategory": "SSPA Kategorie: 1140 - Kapitalschutz",
    "CapitalProtectionWithConditionalCouponWorstOfName": "LUKB Kapitalschutz-Zertifikat mit Bedingtem Coupon",
    "CapitalProtectionWithConditionalCouponWorstOfCategory": "SSPA Kategorie: 1140 - Kapitalschutz",
    "CapitalProtectionWithParticipationCappedName": "LUKB Kapitalschutz-Zertifikat mit Capped Partizipation",
    "CapitalProtectionWithParticipationCappedCategory": "SSPA Kategorie: 1100 - Kapitalschutz",
    "CapitalProtectionWithParticipationCappedWorstOfName": "LUKB Kapitalschutz-Zertifikat mit Capped Partizipation am worst of",
    "CapitalProtectionWithParticipationCappedWorstOfCategory": "SSPA Kategorie: 1100 - Kapitalschutz",
    "CapitalProtectionWithParticipationSumBasketName": "LUKB Kapitalschutz-Zertifikat mit Partizipation am Basket",
    "CapitalProtectionWithParticipationSumBasketCategory": "SSPA Kategorie: 1100 - Kapitalschutz",
    "CapitalProtectionWithParticipationCappedSumBasketName": "LUKB Kapitalschutz-Zertifikat mit Capped Partizipation am Basket",
    "CapitalProtectionWithParticipationCappedSumBasketCategory": "SSPA Kategorie: 1100 - Kapitalschutz",
    "Payment_date_dividend": "Zahlungsdatum",
    "absolute_payment": "Ausgleichszahlungsbetrag",
    "dividends": "Ausgleichszahlungen",
    "performanceSinceIssuePercentWithDividends": "Kursentwicklung seit Emission",
    "DualCurrencyNote": "Dual Currency Note",
    "strikeLevelAndMaxReturn": "Cap Level und maximale Rendite",
    "maxReturn": "max. Rendite",
    "investmentCurrency": "Anlagewährung",
    "alternativeCurrency": "Alternativwährung",
    "cashorphysicalForDualCurrencyNote": "Bar (in der Anlagewährung) oder Konvertierung in Alternativwährung",
    "physicalForDualCurrencyNote": "Konvertierung in Alternativwährung",
    "chart_disclaimer_DualCurrencyNote": "Produktperformance seit Emission, max. Rendite bis Verfall und max. Rendite p.a. basieren auf aktuellen Kursen.<br/>Performance Basiswert und Basiswertpreis basieren auf dem Vortagesschlusskurs."
};